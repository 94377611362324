import { useRef, useState, useEffect } from "react";

const useButtonLoader = (defaultText = "Load", loadingText = "Loading...") => {
  const [isLoading, setLoading] = useState(false);
  const element = useRef(null);

  useEffect(() => {
    if (isLoading) {
      element.current.disabled = true;
      element.current.innerHTML =
        '<span animation="border" role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">Loading...</span></span> ';
    } else {
      if (element.current) {
        element.current.disabled = false;
        element.current.innerHTML = defaultText;
      }
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return [element, setLoading];
};

export default useButtonLoader;
