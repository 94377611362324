import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import { NavLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const SubMenu = ({
  isChild,
  icon,
  name,
  onClose,
  path,
  style,
  toggle,
  fullMenu,
}) => {
  // const [open, setOpen] = useState(false);

  return (
    <>
      <li>
        <a onClick={toggle}>
          <span>
            {/* <i className={icon}></i> */}
            {icon}
          </span>
          {fullMenu ? name : <>&nbsp;</>}
          {fullMenu && (
            <label>
              <i
                className={
                  style === path ? "fa fa-caret-down" : "fa fa-caret-right"
                }
              ></i>
            </label>
          )}
        </a>
        <Collapse in={style === path} timeout="auto" unmountOnExit>
          <div className="sub-menu">
            <ul>
              {isChild &&
                isChild
                  .filter((child) => child.enabled)
                  .map((child) => {
                    return (
                      <li className="" key={child.path}>
                        <NavLink
                          to={child.path}
                          onClick={() => onClose && onClose(false)}
                        >
                          <span>
                            {/* <i className={child.icon}></i> */}
                            <NavigateNextIcon fontSize="small" />
                          </span>
                          {fullMenu ? child.name : <>&nbsp;</>}
                        </NavLink>
                      </li>
                    );
                  })}
            </ul>
          </div>
        </Collapse>
      </li>
    </>
  );
};

export default SubMenu;
